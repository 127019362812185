import * as React from 'react';

export default () => (
  <footer className="footer">
    <div className="content">
      <p>
        {/* <strong>Thanks for reading!</strong> If you have any inquiry of project do not hesitate to contact me{' '} */}
        {/* <span role="img" aria-label="smile">
          😃
        </span> */}
      </p>
    </div>
  </footer>
);
